import React from "react";
import { Link } from "gatsby";



import blogStyles from "./blog.module.css";
import Head from "../components/head";

import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulBlogPost(sort: { order: DESC, fields: publishDate }) {
      edges {
        node {
          slug
          blogImage {
            file {
              url
            }
          }
          author
          publishDate(formatString: "MMMM DD, YYYY")
          title
          blogEntry {
            blogEntry
          }
          authorImage {
            file {
              url
            }
          }
        }
      }
    }
  }
`;

const BlogPage = props => {
  return (
    <>
    <Head title="TSS blogs"></Head>
      {props.data.allContentfulBlogPost.edges.length === 1 &&
      props.data.allContentfulBlogPost.edges[0].node.title === "null" ? (
        <section className={blogStyles.blogSection}>
          <div>
            <div>
              <h2 className={blogStyles.noBlogs}>No blogs currently</h2>
            </div>
          </div>
        </section>
      ) : (
        <>
          <section className={blogStyles.blogSection}>
            <div
              className={blogStyles.BlogBGImage}
              style={{
                backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${props.data.allContentfulBlogPost.edges[0].node.blogImage.file.url})`,
              }}
            ></div>
            <div className={blogStyles.blogCopyContainer}>
              <div>
                <h3>Recent Blog</h3>
                <h2>{props.data.allContentfulBlogPost.edges[0].node.title}</h2>
                <p>
                  {props.data.allContentfulBlogPost.edges[0].node.blogEntry.blogEntry.substring(
                    0,
                    90
                  ) + "..."}
                </p>
                <Link
                  to={`/blog/${props.data.allContentfulBlogPost.edges[0].node.slug}`}
                >
                  Read more
                </Link>
              </div>
            </div>
          </section>

          <section className={blogStyles.pastBlogContainer}>
            <h3>Past Articles</h3>
            <div className={blogStyles.blogItemContainer}>
              {props.data.allContentfulBlogPost.edges.map((edge, index) => {
                if (edge.node.title !== "null") {
                  return (
                    <div key={index} className={blogStyles.blogItem}>
                      <div
                        className={blogStyles.blogItemUpper}
                        style={{
                          backgroundImage: `url(${edge.node.blogImage.file.url})`,
                        }}
                      ></div>
                      <div className={blogStyles.blogItemLower}>
                        <div>
                          <h4>{edge.node.title}</h4>
                          <p>
                            {edge.node.blogEntry.blogEntry.substring(0, 90) +
                              "..."}
                          </p>
                          <Link to={`/blog/${edge.node.slug}`}>Read more</Link>
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
            </div>
          </section>
        </>
      )}
    </>
  );
};

export default BlogPage;
